import { SvgIcon, Tooltip } from '@material-ui/core';
import axios from 'axios';
import { useStaticQuery, graphql, Link } from 'gatsby';
import React, { useState, useEffect } from 'react';

import ArgCounter from 'images/ArgCounter.svg';
import EduGraphGrey from 'images/EduGraphGrey.svg';
import { ContentfulDiscussionNode } from 'models/contentfulModels';

import { StringForm } from 'models/typeAliases';

import ListHeader from './ListHeader';

type Props = {
  topic?: string;
}

const List = (props: Props): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      allContentfulDiscussions {
        edges {
          node {
            title
            topic
            slug
            createdAt
            swarmcheckId
            materialReference {
              slug
            }
          }
        }
      }
    }
  `);

  const [argAmount, setArgAmount] = useState<Record<number, number>>({});

  const getDiscussionsArgumentsDetails = async (): Promise<void> => {
    let numberOfArguments = {};
    const res = await axios.get(`${process.env.SWARMCHECK_PV3_API_URL}/projects/mysl-jak-naukowiec/discussions`);
    res.data.map(d => 
      numberOfArguments = { ...numberOfArguments, ...{ [d.discussionId]: d.numberOfArguments } }
    );
    setArgAmount(numberOfArguments);
  };

  useEffect(() => {
    getDiscussionsArgumentsDetails();
  }, []);

  const sortingList = ['Od najnowszych dyskusji', 'Od najstarszych dyskusji', 'Od największych dyskusji', 'Od najmniejszych dyskusji'];
  const themeList = [ ...new Set(
    data.allContentfulDiscussions.edges.map((item: ContentfulDiscussionNode) => item.node.topic)
  )] as string[];

  const [form, setForm] = useState<StringForm>({ topic: props.topic || 'all', order: sortingList[0] });

  const defaultList = data.allContentfulDiscussions.edges as ContentfulDiscussionNode[];
  let displayedList: ContentfulDiscussionNode[] | undefined;
  if (form.order === sortingList[0]) {
    displayedList = defaultList.sort((a, b) => 
      new Date(b.node.createdAt).getTime() - new Date(a.node.createdAt).getTime()
    );
  }
  else if (form.order === sortingList[1]) {
    displayedList = defaultList.sort((a, b) => 
      new Date(a.node.createdAt).getTime() - new Date(b.node.createdAt).getTime()
    );
  }
  else if (form.order === sortingList[2]) {
    displayedList = defaultList.sort((a, b) => {
      return argAmount[b.node.swarmcheckId] - argAmount[a.node.swarmcheckId];
    });
  }
  else if (form.order === sortingList[3]) {
    displayedList = defaultList.sort((a, b) => {
      return argAmount[a.node.swarmcheckId] - argAmount[b.node.swarmcheckId];
    });
  }
  if (form.topic) {
    if (form.topic === 'all') displayedList = defaultList;
    else displayedList = defaultList.filter(item => item.node.topic === form.topic);
  }

  return (
    <div className="discussion-list" id="list">
      <p className="discussion-list__entry">
        Poniżej znajduje się lista prowadzonych na stronie dyskusji. Każdą z nich możesz rozbudowywać 
        dodając własne argumenty. Niektóre dyskusje są wyróżnione {`ikoną edugrafu: `}
        <SvgIcon component={EduGraphGrey} viewBox="0 0 21 21" fontSize="inherit" />.   
        Oznacza to, że poza
        samymi grafami argumentacji znajdują się w nich również dodatkowe materiały edukacyjne dostępne
        pod przyciskiem "dowiedz się więcej". Jeśli na stronie nie ma jeszcze dyskusji w interesującym 
        Cię temacie, możesz ją założyć klikając przycisk "zaproponuj dyskusję" w prawym górnym rogu ekranu.
      </p>
      <ListHeader 
        title={'Dyskusje'}
        form={form}
        setForm={setForm}
        themeList={themeList}
        sortingList={sortingList}
      />
      <div className="discussion-list__grid">
        {displayedList?.map((item: ContentfulDiscussionNode) => (
          <Link key={item.node.slug} to={`/dyskusje/${item.node.slug}`}>
            <div className="discussion-list__tile">
              <div className="discussion-list__title">
                <h1>{item.node.title}</h1>
                {item.node.materialReference?.slug
                  ? <SvgIcon component={EduGraphGrey} viewBox="0 0 21 21" fontSize="inherit" />
                  : null  
                }
              </div>
              <div className="discussion-list__footer">
                <h2>{`TEMAT: ${item.node.topic}`}</h2>
                <div>
                  {argAmount[item.node.swarmcheckId] 
                    ? <Tooltip title={`Ilość tez: ${argAmount[item.node.swarmcheckId]}`}>
                      <div className="discussion-list__icon">
                        <SvgIcon component={ArgCounter} viewBox="0 0 21 21" fontSize="inherit" />
                        <span>{argAmount[item.node.swarmcheckId]}</span>
                      </div>
                    </Tooltip> 
                    : null
                  }
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default List;