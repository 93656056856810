import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import DiscussionHeader from './DiscussionHeader';
import List from './List';

type Props = {
  topic?: string;
}

const DiscussionList = (props: Props): JSX.Element => {
  
  const data = useStaticQuery(graphql`
    query {
      wave: contentfulAsset(title: {eq: "Path 210"}) {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  `);

  return (
    <div className="discussion">
      <DiscussionHeader />
      <List topic={props.topic} />
      <Img className="wave wave--bottom" fluid={data.wave.fluid} />
    </div>
  );
};

export default DiscussionList;