import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

const DiscussionHeader = (): JSX.Element => {

  const data = useStaticQuery(graphql`
    query {
      contentfulAsset(title: {eq: "WhiteWave"}) {
        fluid(maxWidth: 1920) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
    }
  `);
  
  return (
    <div className="discussion-header">
      <div className="discussion-header__cat">
        {`HOME / ARGUMENTUJ Z NAMI!`}
      </div>
      <div className="discussion-header__title">
        DOŁĄCZ DO DYSKUSJI!
      </div>
      <Link to={`/dyskusje/#list`} className="discussion-header__arrow">
        <ArrowDownwardIcon fontSize="inherit"/>
      </Link>
      <Img className="wave wave--bottom" fluid={data.contentfulAsset.fluid} />
    </div>
  );
};

export default DiscussionHeader;