import React from 'react';

import DiscussionList from 'components/DiscussionList';
import Footer from 'components/Footer';
import Layout from 'components/Layout';
import SEO from 'components/SEO';

type Location = {
  location: { state: { topic: string }};
}

const discusion = ({ location }: Location): JSX.Element => {

  const filteredTopic = location && location.state ? location.state.topic : null;

  return (
    <Layout discussion>
      <SEO title="Argumentuj z nami!" />
      {filteredTopic ? <DiscussionList topic={filteredTopic}/> : <DiscussionList />}
      <Footer />
    </Layout>
  );
};

export default discusion;